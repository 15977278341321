import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  GetLicensesVulnerabilitiesForReportQueryDocument,
  GetLicensesVulnerabilitiesForRunQueryDocument,
  LicensesScanStatusQueryDocument,
} from '@codenteam/portal/graphql';
import { map } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LicenseAndVulnerabilityService {
  constructor(private apollo: Apollo) {}

  getLicensesAndVulnerabilitiesForRun(runId: string) {
    return this.apollo
      .query({
        query: GetLicensesVulnerabilitiesForRunQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((response) => response.data?.getLicensesVulnerabilitiesForRun));
  }

  getLicensesAndVulnerabilitiesForReport(runId: string) {
    return this.apollo
      .query({
        query: GetLicensesVulnerabilitiesForReportQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(
        map((response) => response.data?.getLicensesVulnerabilitiesForReport)
      );
  }

  getLicAndVulnScanStatus(runId: string) {
    return this.apollo
      .query({
        query: LicensesScanStatusQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((response) => response.data?.LicensesScanStatus));
  }
}
