import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  AfterViewInit,
  afterNextRender,
  AfterRenderPhase,
  ViewContainerRef,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import * as d3 from 'd3';
import {
  colorScheme,
  secondaryColorScheme,
} from '../color-scheme/color-scheme';
import { NameValue } from '../types/name-value.type';
@Component({
  selector: 'codenteam-pie-graph',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pie-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./pie-graph.component.scss'],
})
export class PieGraphComponent implements AfterViewInit {
  @ViewChild('chart', { static: true }) chart!: ElementRef;
  viewPieChart: boolean;
  @Input()
  showLegend?: boolean;
  @Input()
  middlePercentage?: number;
  @Input()
  title: string;
  public _data: NameValue[] = [];
  @Input() set data(value: NameValue[]) {
    this._data = value;
    if (!this._data) {
      return;
    }
    this.init();
  }

  fontSizeOfLegend: number;
  private width: number;
  private height: number;
  radius: number;
  private pie: any;
  private slices: any;
  private colors: any;
  private svg: d3.Selection<SVGSVGElement, any, any, any>;
  private mainContainer: d3.Selection<SVGGElement, any, any, any>;
  private colors2 = secondaryColorScheme;

  // Legend
  numberOfItemsPerRow: number;
  public widthOfItem: number;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.init();
  }

  public init() {
    // Get width and height

    this.width = this.viewRef.element.nativeElement.offsetWidth;
    this.height = this.viewRef.element.nativeElement.offsetHeight;
    if (!this.width) {
      return;
    }

    if (this._data.length > 2) {
      this.numberOfItemsPerRow = 2;
      this.fontSizeOfLegend = 30;
      this.widthOfItem = this.width / 2.5;
    } else if (window.innerWidth < 1280) {
      this.numberOfItemsPerRow = 1;
      this.widthOfItem = this.width - 20;
    } else {
      this.numberOfItemsPerRow = 2;
      this.widthOfItem = this.width / 2;
    }

    this.checkDataValues();
    if (this.viewPieChart) {
      this.drawSlices();
    }
  }
  private checkDataValues() {
    const allAreNaN = this._data.every(
      (item) => typeof item.value === 'number' && isNaN(item.value)
    );
    if (allAreNaN) {
      this.viewPieChart = false;
    } else {
      this.viewPieChart = true;
    }
  }
  private drawSlices(): void {
    const numberOfLinesOfLegend = this._data.length / this.numberOfItemsPerRow;
    if (this._data.length > 2 && this.numberOfItemsPerRow == 1) {
      this.fontSizeOfLegend = 30;
    } else {
      this.fontSizeOfLegend = 45;
    }
    d3.select(this.chart.nativeElement).selectAll('svg').remove();
    this.svg = d3.select(this.chart.nativeElement).append('svg');
    this.colors = d3.scaleOrdinal(colorScheme);
    this.mainContainer = this.svg
      .append('g')
      .attr('transform', `translate(${this.radius},${this.radius})`)
      .style('transform-origin', 'center center')
      .style('scale', '0.85');
    this.pie = d3
      .pie()
      .sort(null)
      .value((d: any) => d.value);

    this.radius = (Math.min(this.width, this.height) / 2) * 0.9;

    // If the height wouldn't allow (numberOfLinesOfLegend) lines of legends, reduce the radius
    if (this.showLegend !== false) {
      if (
        this.height <
        this.fontSizeOfLegend * numberOfLinesOfLegend + this.radius * 2
      ) {
        this.radius =
          (this.height - this.fontSizeOfLegend * numberOfLinesOfLegend) / 2;
      }
    }
    this.svg
      // .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewBox', `0 0 ${this.width} ${this.height}`);
    this.svg
      .select('g')
      .attr(
        'transform',
        'translate(' + this.width / 2 + ',' + this.radius + ')'
      );

    const arc = d3
      .arc()
      .outerRadius(this.radius)
      .innerRadius((d: any, i) => {
        const max = this.radius * 0.75;
        const min = this.radius * 0.35;
        const step = (max - min) / 100.0;
        return step * (100 - d.data.value) + min;
      });

    this.slices = this.mainContainer
      .selectAll('path')
      .remove()
      .exit()
      .data(this.pie(this._data))
      .enter()
      .append('g');

    this.slices
      .append('path')
      .attr('d', arc as any)
      .attr('fill', (d: NameValue, i: number) => {
        if (this._data[i].color) {
          return this._data[i].color;
        } else {
          return this.colors(i);
        }
      });

    // Add title for each slice
    this.slices
      .append('title')
      .text((d: NameValue, i: number) => {
        return `${this._data[i].name}: ${this._data[i].value}%`;
      })
      .attr('data-test', 'slice-tooltip');

    this.slices = this.mainContainer
      .selectAll('text')
      .data(this.pie(this._data))
      .enter()
      .append('g')
      .append('text')
      .attr('d', arc as any);

    this.slices
      .text((d: any, i: any) => {
        if (this._data[i].value > 3) {
          return this._data[i].value + '%';
        } else {
          return '';
        }
      })
      .attr('data-test', 'slice-value')

      .attr('x', (d: d3.DefaultArcObject) => {
        return arc.centroid(d)[0];
      })
      .attr('y', (d: d3.DefaultArcObject) => {
        return arc.centroid(d)[1];
      })
      .style('fill', this.colors2[1])
      .attr('text-anchor', 'middle')
      .style('font-size', '18px');

    this.slices = this.mainContainer
      .selectAll('text')
      .exit()
      .data(this.pie(this._data))
      .enter()
      .append('g')
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size', '16px')
      .attr('y', 10)
      .attr('title', 'total')
      .style('fill', this.colors2[0])
      .text(() => {
        if (
          (this.middlePercentage !== null,
          typeof this.middlePercentage !== 'undefined')
        ) {
          return this.middlePercentage + '%';
        } else {
          return '';
        }
      });
    if (this.showLegend !== false) {
      //////////////
      const legendG = this.svg
        .selectAll('.legend')
        .data(this._data)

        .enter()

        .append('g')
        .attr('transform', (d: NameValue, i: number) => {
          const xOff = ((i % this.numberOfItemsPerRow) * this.width) / 2;
          const yOff =
            // Go down to the end
            numberOfLinesOfLegend * this.fontSizeOfLegend +
            this.radius * 2 +
            10 -
            // Go up the number of rows
            Math.floor(i / this.numberOfItemsPerRow) * this.fontSizeOfLegend -
            // Go to the top of the line
            this.fontSizeOfLegend;
          return 'translate(' + xOff + ',' + yOff + ')';
        })
        .attr('class', 'legend');

      legendG
        .append('g')
        .style('font-size', '15px')
        .style('line-height', this.fontSizeOfLegend + 'px')
        .attr('y', 4)
        .attr('x', 10)
        .attr('transform', (d: NameValue, i: number) => {
          const yOff = Math.floor(i / this.numberOfItemsPerRow);
          return 'translate(0,' + yOff * 10 + ')';
        })
        .attr('fill', this.colors2[0])
        .append('foreignObject')
        .attr('width', this.widthOfItem)
        .attr('height', this.fontSizeOfLegend)
        .append('xhtml:div')
        .html((d: NameValue, i: number) => {
          const color = this._data[i].color ?? this.colors(i);

          if (this._data[i].value === 0) {
            return '';
          }
          return `<span style="color:${color}; float: left; font-size: ${this.fontSizeOfLegend}px;">&#8226;</span>&nbsp;<span style="line-height: 48px; font-weight:300;" class='text-lg md:text-base text-gray-400'>${d.name}</span>`;
        })
        .attr('data-test', 'legend-name')

        .style('color', 'white')
        .style('margin-left', '10px')
        .attr('class', 'truncate')
        .attr('title', (d: NameValue, i: number) => {
          return d.name;
        })
        .attr('data-test', 'legend-tooltip');
    }
  }

  constructor(
    private readonly viewRef: ViewContainerRef,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.radius = 0;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
      this.changeDetectorRef.detectChanges();
    }, 10);
  }
}
