<div>
  <figure
    #chart
    class="{{
      !_data || _data.length === 0 || !viewPieChart ? 'opacity-0 p-3' : 'p-3'
    }}"
  ></figure>

  <div
    class="flex !h-full !items-center !justify-center !align-middle"
    *ngIf="!_data || _data.length === 0 || !viewPieChart"
  >
    <div
      class="rounded-md bg-slate-700 px-5 py-3 text-base font-light text-gray-200 shadow-[0px_10px_12px_0px_rgba(13,17,25,0.60)] md:mb-20 md:text-3xl"
      data-test="no-data-message"
    >
      <p class="mb-0 text-center md:mb-2">NO DATA</p>
    </div>
  </div>
</div>
