import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, map, mergeMap } from 'rxjs';
import { RouteReaderService, route$ } from './route-reader.service';

@Injectable({ providedIn: 'root' })
export class FilesService {
  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private routeReaderService: RouteReaderService
  ) {}

  getFilesCount(runId$: Observable<string>, filters:{profileId$?: Observable<number>, teamId$? : Observable<number>}) {
    // Move out of here
    const checkFilter$= filters.profileId$? filters.profileId$:filters.teamId$;
    return combineLatest([runId$, checkFilter$]).pipe(
      mergeMap(([runId, value]) => {
        const checkType= filters.profileId$? {profileId:value}:{teamId:value};
        return this.apiService.filesContributedBy(runId, checkType).pipe(
          map((res) => {
            if (res) {
              return { value: res };
            } else {
              return { value: 0 };
            }
          })
        );
      })
    );
  }
  getDirectoriesCount(
    runId$: Observable<string>,
    profileId$: Observable<number>
  ) {
    return combineLatest([runId$, profileId$]).pipe(
      mergeMap(([runId, profileId]) => {
        return this.apiService
          .directoriesContributedBy(runId, {profileId})
          .pipe(
            map((res) => {
              if (res) {
                return { value: res };
              } else {
                return { value: 0 };
              }
            })
          );
      })
    );
  }

  getMaxRootDepth(runId$: Observable<string>) {
    return runId$.pipe(
      mergeMap((runId) => {
        return this.apiService.getMaxRootDepth(runId).pipe(
          map((res) => {
            if (res < 1) {
              return 0;
            } else {
              return res;
            }
          })
        );
      })
    );
  }
  countFiles(runId$: Observable<string>) {
    return runId$.pipe(
      mergeMap((runId) => {
        return this.apiService.countFiles(runId);
      })
    );
  }
  countDirectories(runId$: Observable<string>) {
    return runId$.pipe(
      mergeMap((runId) => {
        return this.apiService.countDirectories(runId);
      })
    );
  }

  getMaxDirectoriesPaths(runId$: Observable<string>) {
    const maxRootDepth = this.getMaxRootDepth(runId$);
    return combineLatest([runId$, maxRootDepth])
      .pipe(
        mergeMap(([runId, depth]) => {
          return this.apiService.getMaxDepthDirectories(runId, {
            depth,
          });
        })
      )
      .pipe(
        map((result) => {
          return result.map((directory) => directory.path.toString());
        })
      );
  }
}
